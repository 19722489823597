
import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';
import { Form } from 'vee-validate';
import { useProgrammatic } from '@oruga-ui/oruga-next';

export default defineComponent({
    mixins: [Base],
    emits: ['reload'],
    data() {
        return {
            file: undefined,
        };
    },
    methods: {
        importItems(): void {
            const formData = new FormData();
            formData.append('file', this.file);

            this.post('products/import', formData)
                .then(() => {
                    this.$router.push({ name: 'products.list' });
                    this.$emit('reload');
                });
        },
    },
});
